<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/eyebank-logo.png')"
              max-width="220px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text class="text-center">
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welcome to EBSR!
          </p>
          <!-- <p class="mb-2">
            Please sign-in to your account and start the adventure
          </p> -->
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
              @keyup.enter="login"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              @keyup.enter="login"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                href="/pages/forgot"
                class="mt-1"
              >
                Forgot Password?
              </a>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-10 mb-3"
              @click.prevent="login()"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <v-snackbar v-model="snackbar" :timeout="timeout">
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
          </template>
        </v-snackbar>

        <!-- create new account  -->
        <!-- <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New on our platform?
          </span>
          <router-link :to="{name:'pages-register'}">
            Create an account
          </router-link>
        </v-card-text>

         
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social links -->
        <!-- <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    
    <!-- <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

 
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
// import Cookies from 'universal-cookie';
import { ref } from '@vue/composition-api'
// const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
// const token = localData.token
var axios = require('axios').default
export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      
      isPasswordVisible,
      email,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
    }
  },
  mounted:{

  },
 
  methods: {
    async login() {
      try {
        if (!this.email || !this.password) {
          return
        }
        let res = await axios.request({
          method: 'POST',
          url: `https://api.stage.ebsr.in/login`,
          headers: {
            'Content-type': 'application/json',
          },
          data: {
            email: this.email,
            password: this.password,
          },
        })
        if(res.data.status == 200){
          let localData = {
              "token": res.data.token,
              "user_name":res.data.data.name,
              "user_id":res.data.data.id,
              "user_number":res.data.data.phone_number,
              "profilePicture":res.data.data.profile_picture,
              "signatureImage":res.data.data.signature,
              "userDesignation":res.data.data.designation,
              "Role_id":res.data.data.role_id
          }
          const newvalue = JSON.stringify(localData)
          localStorage.setItem('token', JSON.stringify(localData))
          this.$cookies.set('login',JSON.stringify(localData), "45d")

          const userDesignation = this.$cookies.get("login").Role_id
          // if(userDesignation == ""){
          //   this.$router.push({name:'/'})
          // }
          if(userDesignation === 132){
          this.$router.push({ name: 'dashboard' })
          this.snackbarText = res.data.message
          this.snackbar = true
          }else if(userDesignation === 6){
          this.$router.push({ name: 'dashboardAssistentManager' })
          this.snackbarText = res.data.message
          this.snackbar = true
          }else if(userDesignation === 7){
          this.$router.push({ name: 'AccountsDashboard' })
          this.snackbarText = res.data.message
          this.snackbar = true
          }else if(userDesignation === 2){
          this.$router.push({ name: 'DashboardLabTechnician' })
          this.snackbarText = res.data.message
          this.snackbar = true
          }
          else if(userDesignation === 134){
          this.$router.push({ name: 'DashboardLabTechnician' })
          this.snackbarText = res.data.message
          this.snackbar = true
          }
          else if(userDesignation === 8){
          this.$router.push({ name: 'dashboard' })
          this.snackbarText = res.data.message
          this.snackbar = true
          }
          else if(userDesignation === 1){
          this.$router.push({ name: 'DashboardCounsellor' })
          this.snackbarText = res.data.message
          this.snackbar = true
          }
          else if(userDesignation === 3){
          this.$router.push({ name: 'DashboardMedicalDirector' })
          this.snackbarText = res.data.message
          this.snackbar = true
          }
          else if(userDesignation === 9){
          this.$router.push({ name: 'DashboardHCRP' })
          this.snackbarText = res.data.message
          this.snackbar = true
          }
          else if(userDesignation === 124){
          this.$router.push({ name: 'DashboardTissueEvaluator' })
          this.snackbarText = res.data.message
          this.snackbar = true
          }
        }else{
          this.$router.push({ path: '/' })
          this.snackbarText = res.data.message
          this.snackbar = true
        }
      } catch (error) {
        console.log(error)
      }
       
    },
    
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
